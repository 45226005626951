/* eslint-disable */
import React, { Component } from "react"

const VideoPage98733 = () => {
  return (
    <div>
      <h1>Animation Preview</h1>
      <video
        src={require("./videos/td-wealth-vid-min.mp4")}
        width="auto"
        height="auto"
        loop
        autoPlay
        controls
        style={{ maxWidth: "80vh", maxHeight: "80vw", width: "100%" }}
      />
    </div>
  )
}

export default VideoPage98733
